import React, { useState } from 'react'
import TextInput from '../components/forms/TextInput';
import TextareaInput from '../components/forms/TextareaInput';
import { useForm } from 'react-hook-form/dist/react-hook-form.ie11';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image/withIEPolyfill'
import axios from 'axios';

const ContactUsPage = ({data}) => {
    
    const {register, handleSubmit, watch, errors, reset} = useForm();
    const watchFields = watch();
    const [notifyMessage, setNotifyMessage] = useState('');

   
    const headSource = [
        data.imageSharp.big,
        {
            ...data.imageSharp.medium,
            media: `(max-width: 62.5em) and (min-width: 40.0625em)`
        },
        {
            ...data.imageSharp.small,
            media: `(max-width: 40em)`
        },
    ]


    const onSubmit = (data, e) => {

       
        // Uncomment this when ready obviously
        const response =  axios.post(
            '/forms/contact-form.php',
            data ,
            { headers: { 'Content-Type': 'application/json' } }
        ).then(res => {
        
            if(res.data === true){
                //console.log('true')
                setNotifyMessage('Thank you we will be in touch shortly.')
                e.target.reset();

            }else {
               // console.log('false' + res.data)
                setNotifyMessage(res.data);
            }
           
        }) 
    
      

    }

    const FormMessage = ({message}) => {
        return (
            <>
            <div className="notify-overlay" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)} role="button" tabIndex="0">
                <div className="notification" onClick={() => setNotifyMessage(null)} onKeyDown={() => setNotifyMessage(null)}  role="button" tabIndex="-1">
                    <p className="success-message">
                        {message}
                    </p>
                </div>
            </div>
            
           
            </>
        );
    }



    return (
        <Layout pageClass="contact-us-page">
        <SEO 
            title="Get in Touch"
            description={"Get in touch with Turning Tables, New Zealand hospitality trainers."}  />

        <div className="page-heading">
            <Img 
                fixed={headSource}
                alt={'Contact Us'}
                objectFit="cover"
                objectPosition="50% 50%"
                style={{
                    width:'100%',
                    display:'block'
                }}
                
            />
            <div className="image-overlay">
                <div>
                <h1>Contact Us</h1>
                    <div className="header-underline"></div>
                </div>
            </div>
        </div>
            <div className="top-strip "></div>
        <main className="main">
            <div className="typography inner">

                
                <p>Keen to find out more?</p>

                <p>Please feel free to get in contact for a no obligation chat about your training ideas and requirements….</p>
               

                <div className="contact-holder">
                    
            
                    <div className="contact-form">
                        <p className="center">Fill out the form below and we will get back to you as soon as possible!</p>

                        <form name="contact" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <input type="hidden" name="bot-field" />

                            <div className="form-flex">
                                <TextInput reg={register({required: "Name is Required"})}  name="name" label="Name" type="text" errors={errors.name} />

                                <TextInput reg={register({required: "Email is Required"})}  name="email" label="Email" type="text" errors={errors.email} />

                                <TextInput reg={register()}  name="phone" label="Phone" type="text" errors={errors.phone} />

                                <TextareaInput reg={register({required: "Message is Required"})}  name="message" label="Message"  errors={errors.message} />
                            </div>
                            



                            <input type="submit" className="submit-btn btn" value="Send" />
                        </form>
                    </div> 

                    <address>

                        <div className="phone">
                            <h5>Phone</h5>
                            <a href="tel:+64272457233">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <path id="Icon_material-local-phone" data-name="Icon material-local-phone" d="M8.12,12.29a15.149,15.149,0,0,0,6.59,6.59l2.2-2.2a.994.994,0,0,1,1.02-.24,11.407,11.407,0,0,0,3.57.57,1,1,0,0,1,1,1V21.5a1,1,0,0,1-1,1,17,17,0,0,1-17-17,1,1,0,0,1,1-1H9a1,1,0,0,1,1,1,11.36,11.36,0,0,0,.57,3.57,1,1,0,0,1-.25,1.02l-2.2,2.2Z" transform="translate(-4.5 -4.5)"/>
                                </svg>

                                <span>(+64) 27 245 7233</span>
                            </a>
                            
                           
                            
                        </div>

                        <div className="email">
                            <h5>Email</h5>
                            <a href="mailto:info@turningtables.co.nz">

                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16">
                                    <path id="Icon_material-email" data-name="Icon material-email" d="M21,6H5A2,2,0,0,0,3.01,8L3,20a2.006,2.006,0,0,0,2,2H21a2.006,2.006,0,0,0,2-2V8A2.006,2.006,0,0,0,21,6Zm0,4-8,5L5,10V8l8,5,8-5Z" transform="translate(-3 -6)"/>
                                </svg>

                                <span> info@turningtables.co.nz</span>
                           </a>
                        </div>

                        <div className="address">
                            <h5>Address</h5>

                            <div className="address-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.7" height="21" viewBox="0 0 14.7 21">
                                    <path id="Icon_material-location-on" data-name="Icon material-location-on" d="M14.85,3A7.345,7.345,0,0,0,7.5,10.35C7.5,15.863,14.85,24,14.85,24s7.35-8.137,7.35-13.65A7.345,7.345,0,0,0,14.85,3Zm0,9.975a2.625,2.625,0,1,1,2.625-2.625A2.626,2.626,0,0,1,14.85,12.975Z" transform="translate(-7.5 -3)"/>
                                </svg>

                                <div>
                                    <span>6 Ward Place,</span>
                                    <span>Richmond Heights,</span>
                                    <span>Taupo 3330, New Zealand</span>
                                </div>
                                
                            </div>
                            
                        </div>
                        
                        
                        
                                        
                    </address>
                
                </div>
    
                
                

                
            </div>
        </main>
        {notifyMessage ? <FormMessage message={notifyMessage} /> : ''}
        </Layout>

    )
}
export const pageQuery = graphql`
    query {
        imageSharp(resolutions: {originalName: {eq: "header-getInTouch-2000w-crop.jpg"}}) {

            big: fixed (width: 2000, height: 390, quality: 100) {
                ...GatsbyImageSharpFixed
            }
            medium: fixed (width: 1000, height: 390, quality: 100) {
                ...GatsbyImageSharpFixed
            }
            small: fixed (width: 480, height: 390, quality: 100) {
                ...GatsbyImageSharpFixed
            }      
            
        }
    
    }
`

export default ContactUsPage